<template>
    <div class="imagesLib" v-if="periodPredictSummary.Data != undefined">
        <!-- 列表 -->

        <div class="wordList">
            <span v-for="(item, index) in tztkList.Data" :key="index" style="" class="nav-item"
                @click="changeTztkId(item.Id)">
                <a :class="(item.Id == nowTztkIndex ? 'on-pick-tztk' : '')">{{ item.PublicationName }}</a>
            </span>
        </div>

        <!-- 內容 -->
        <div class="tktzContent">
            <!-- 期號&功能選單 -->
            <!--
                <div class="selectBar">
                    <div class="dataNav">
                        //向前翻页按钮
                        <button class="dataNavPrev" @click="navPrev"></button>
                        <ul class="dataNavList" ref="dataNavList">
                            <li class="dataNavListItem" :class="selectPeroid == item.PeriodNo ? 'active' : ''" v-for="(item, index) in allPeriodNo.Data.Data" :key="index"
                                :style="{transform:'translateX(-'+move+'px)'}" @click="changePeriodNo(item.PeriodNo)">{{item.PeriodNo}} 期</li>
                            //move变量为每次移动的距离，菜单项向左移的所以移动距离是负值，负号写好了，使move是正值方便计算
                        </ul>
                        //向后翻页按钮
                        <button class="dataNavNext" @click="navNext" style=""></button>
                    </div>
                    <hr size="1px" align="center" width="90%" color="#DBDBDB">
                </div>
            -->

            <div class="topDrawNumber"
                :style="currentLotteryName == 'xglhc' ? 'width: 1142px; margin-left: 147px; height: 119px' : ''">
                <div class="showPeriodNo">第&nbsp;<span>{{periodPredictSummary.Data.PeriodNo}}</span>&nbsp;期开奖号码</div>
                <div class="selectPeroid">
                    <select v-model="selectPeroid" @change="changePeriodNo">
                        <template v-for="(item, key) in allPeriodNo.Data.Data">
                            <option :key="item.PeriodNo" :value="item.PeriodNo">{{item.PeriodNo}}期</option>
                        </template>
                    </select>

                    <span class="openTime" v-if="currentLotteryName == 'lucky7daily'">每日22:00:00开奖</span>
                    <span class="openTime xglhcLib" v-if="currentLotteryName == 'xglhc'">每周二、四、六 21:30:00开奖</span>
                    <span class="openTime" v-if="currentLotteryName == 'kl8lhc'">每日21:30:00开奖</span>
                </div>
                <div class="showBall">
                    <div class="displayType">
                        <template v-if="periodPredictSummary.Data.PreDrawCode != null">
                            <div class="ballWrapper">
                                <div :class="`${currentLotteryName} ball ball-${parseInt(num)}`"
                                    v-for="(num,index) in periodPredictSummary.Data.PreDrawCode.split(',').slice(0,6)"
                                    :key="index">{{num | padLeft(2)}}</div>
                                <div class="add">+</div>
                                <div
                                    :class="`${currentLotteryName} ball ball-${parseInt(periodPredictSummary.Data.SpecialDrawNo)}`">
                                    {{parseInt(periodPredictSummary.Data.SpecialDrawNo) | padLeft(2)}}
                                </div>
                            </div>

                            <div>
                                <div class="textWrapper mappingBall_sx">
                                    <div v-for="(sx,index) in periodPredictSummary.Data.PreDrawZodiac.split(',').slice(0,6)"
                                        class="text" :key="index+'-sx'">
                                        {{sx}}
                                    </div>
                                    <div class=""></div>
                                    <div class="text">{{periodPredictSummary.Data.SpecialDrawNoZodiac}}</div>
                                </div>
                            </div>
                        </template>
                        <template v-else>
                            <span>暂无数据</span>
                        </template>
                    </div>

                </div>
                <div class="btmStyle">
                    <div class="btm">
                        <span><a :href="imgSrc">保存图片</a></span>
                    </div>
                    <div class="btm">
                        <span>
                            <a href="javascript:///" @click="printImg()">打印图片</a>
                        </span>
                    </div>
                </div>
            </div>
            <!--圖片-->
            <htmlPrinter :html="printHtml" />
            <template v-if="currentLotteryName == 'xglhc'">
                <div class="selectImgBtm">
                    <div class="btmStyle">
                        <div @click="changeImg('A')" :class="selectImgNumber == 'A' ? 'btm active' : 'btm'">
                            <span>{{periodPredictSummary.Data.AName}}</span>
                        </div>
                        <div @click="changeImg('B')" :class="selectImgNumber == 'B' ? 'btm active' : 'btm'">
                            <span>{{periodPredictSummary.Data.BName}}</span>
                        </div>
                    </div>

                </div>
            </template>
            <div class="showImagesLib" ref="saveimage">
                <img class="tzimg" :src="imgSrc">
            </div>
            <!-- 英國六才有的說明 -->
            <div class="bottom" v-if="currentLotteryName == 'lucky7daily' || currentLotteryName == 'kl8lhc'">
                <!--本期-->
                <ImagesLibExplain :info="periodPredictSummary.Data.CurMystery"></ImagesLibExplain>
                <!--上期-->
                <ImagesLibExplain :info="periodPredictSummary.Data.PreMystery"></ImagesLibExplain>
            </div>
        </div>
    </div>
</template>

<script>

import {getPredictPublication, getPredictPublicationPeriod, getPeriodPredictSummary} from "@/api/api.js"; //英國六
import {getLhcKBList, getLhcKBImgALL, getGetLhcKBImg} from "@/api/api.js"; //香港六
import { mapState, mapGetters, mapActions } from 'vuex';
import ImagesLibExplain from '@/components/image_libs/ImagesLibExplain'
import HtmlPrinter from '@/components/image_libs/HtmlPrinter'

export default {
    name: 'ImagesLib',
    components: {
        // NewsCollection
        ImagesLibExplain,
        HtmlPrinter
    },
    data() {
        return {
            currentLotteryName: '',
            nowTztkIndex: 0,
            tztkList: [],
            publicationId:'',
            lotteryHistoryStatistics: '',
            firstPeriodNo: '',
            allPeriodNo: '',
            periodPredictSummary: '',
            selectPeroid:'',
            printHtml: '',
            imgSrc: '',
            selectImgNumber:'A',

            move: 0,
            dataNavListRealWidth: 0,
            dataNavListViewWidth: 0,
        }
    },
    computed: {
        ...mapState([
            "baseInfo"
        ]),
    },
    methods: {
        async init() {
            this.periodPredictSummary = {};
            if(this.currentLotteryName == 'lucky7daily'){
                this.tztkList = await getPredictPublication('1800');
                this.publicationId = this.tztkList.Data[0].Id;//預設都拿圖紙圖庫第一個ID
                this.nowTztkIndex = this.publicationId;
                this.allPeriodNo = await getPredictPublicationPeriod(this.publicationId, 1, 50)
                this.firstPeriodNo = this.allPeriodNo.Data.Data[0].PeriodNo; //取得該類型的圖紙期號, 預設取得第一個
                this.selectPeroid = this.firstPeriodNo;
                //該期的圖紙資訊, 含珠子生肖
                this.periodPredictSummary =  await getPeriodPredictSummary(this.publicationId, this.firstPeriodNo);
                //圖紙位置
                this.imgSrc = this.periodPredictSummary.Data.Host + this.periodPredictSummary.Data.Drawing.LayoutList[0].DrawingUrl;
            } else if(this.currentLotteryName == 'kl8lhc'){
              this.tztkList = await getPredictPublication('500');
              this.publicationId = this.tztkList.Data[0].Id;//預設都拿圖紙圖庫第一個ID
              this.nowTztkIndex = this.publicationId;

              this.allPeriodNo = await getPredictPublicationPeriod(this.publicationId, 1, 50)
              this.firstPeriodNo = this.allPeriodNo.Data.Data[0].PeriodNo; //取得該類型的圖紙期號, 預設取得第一個
              this.selectPeroid = this.firstPeriodNo;
              //該期的圖紙資訊, 含珠子生肖
              this.periodPredictSummary =  await getPeriodPredictSummary(this.publicationId, this.firstPeriodNo);
              console.log(this.periodPredictSummary)
              //圖紙位置

              this.imgSrc = this.periodPredictSummary.Data.Host + this.periodPredictSummary.Data.Drawing.LayoutList[0].DrawingUrl;
            } else if(this.currentLotteryName == 'xglhc'){
                //轉換香港六合彩變數名稱
                let tempInfo = await getLhcKBList();
                this.tztkList = {
                    Status : tempInfo.Status,
                    Data : []
                }
                tempInfo.Data.forEach(element => {
                    this.tztkList.Data.push({
                        Id: element.Id,
                        PublicationName: element.KBTypeName
                    })
                });
                this.publicationId = this.tztkList.Data[0].Id;//預設都拿圖紙圖庫第一個ID
                this.nowTztkIndex = this.publicationId;

                tempInfo = await getLhcKBImgALL(this.publicationId)
                this.allPeriodNo = {
                    Status : tempInfo.Status,
                    Data : {
                        Data: []
                    }
                }
                for(let i = 0, j = tempInfo.Data.length > 10 ? 10 : tempInfo.Data.length; i < j; i++){
                    this.allPeriodNo.Data.Data.push(tempInfo.Data[i])
                }
                this.firstPeriodNo = this.allPeriodNo.Data.Data[0].PeriodNo; //取得該類型的圖紙期號, 預設取得第一個
                this.selectPeroid = this.firstPeriodNo;

                this.periodPredictSummary =  await getGetLhcKBImg(this.publicationId, this.firstPeriodNo);
                this.imgSrc = this.periodPredictSummary.Data.AUrl;

                this.selectImgNumber = 'A';
                
            }
            this.move = 0;
        },
        changePage(i) {
            //this.$router.push(this.pageUrl[i]);
        },
        refreshLotteryCard() {
            this.currentLotteryName = this.$route.params.name;
            this.init();
        },
        async changeTztkId(nowTztkIndex) {
            this.nowTztkIndex = nowTztkIndex;

            this.publicationId = nowTztkIndex;
            if(this.currentLotteryName == 'lucky7daily'||this.currentLotteryName == 'kl8lhc'){
                this.allPeriodNo = await getPredictPublicationPeriod(this.publicationId, 1, 50)
                this.firstPeriodNo = this.allPeriodNo.Data.Data[0].PeriodNo; //取得該類型的圖紙期號, 預設取得第一個
                this.selectPeroid = this.firstPeriodNo;
                //該期的圖紙資訊, 含珠子生肖
                this.periodPredictSummary =  await getPeriodPredictSummary(this.publicationId, this.firstPeriodNo);
                this.imgSrc = this.periodPredictSummary.Data.Host + this.periodPredictSummary.Data.Drawing.LayoutList[0].DrawingUrl;
            } else {
                let tempInfo = await getLhcKBImgALL(this.publicationId)
                this.allPeriodNo = {
                    Status : tempInfo.Status,
                    Data : {
                        Data: []
                    }
                }
                for(let i = 0, j = tempInfo.Data.length > 10 ? 10 : tempInfo.Data.length; i < j; i++){
                    this.allPeriodNo.Data.Data.push(tempInfo.Data[i])
                }
                this.firstPeriodNo = this.allPeriodNo.Data.Data[0].PeriodNo; //取得該類型的圖紙期號, 預設取得第一個
                this.selectPeroid = this.firstPeriodNo;

                this.periodPredictSummary =  await getGetLhcKBImg(this.publicationId, this.firstPeriodNo);
                this.imgSrc = this.periodPredictSummary.Data.AUrl;
                
                this.selectImgNumber = 'A';
            }
            
            this.move = 0;
        },
        //下拉選單選取旗號
        async changePeriodNo(val){
            if (this.currentLotteryName == 'lucky7daily'||this.currentLotteryName == 'kl8lhc') {
                this.periodPredictSummary = await getPeriodPredictSummary(this.publicationId, this.selectPeroid);
                this.imgSrc = this.periodPredictSummary.Data.Host + this.periodPredictSummary.Data.Drawing.LayoutList[0].DrawingUrl;
            } else {
                this.periodPredictSummary = await getGetLhcKBImg(this.publicationId, this.selectPeroid);
                this.imgSrc = this.periodPredictSummary.Data.AUrl;
                this.selectImgNumber = 'A';
            }
        },
        printImg() {
            let self = this;
            this.printHtml = '';
            setTimeout(function(){
                self.printHtml = self.$refs.saveimage.innerHTML;
            }, 50);
        },
        changeImg(val){
            if(this.selectImgNumber == val){
                return false;
            } else {
                this.selectImgNumber = val;
                if(val == 'A'){
                    this.imgSrc = this.periodPredictSummary.Data.AUrl;
                } else {
                    this.imgSrc = this.periodPredictSummary.Data.BUrl;
                }
            }
        },
        // 当菜单项向右的可移动距离大于单个菜单项的宽度时，move减去一个菜单项的宽度（即右移移动一个菜单项的宽度），
        // 当菜单项向右的可移动距离小于单个菜单项的宽度时，move等于0（即回到最开始没有移动的状态）
        navPrev() {
            if (this.move > 414) {
                this.move = this.move - 414;
            } else {
                this.move = 0
            }
        },
        // 当菜单项向左的可移动距离大于单个菜单项的宽度时，move减去一个菜单项的宽度（即左移移动一个菜单项的宽度），
        // 当菜单项向左的可移动距离小于单个菜单项的宽度时，move等于可以向左移动的最大值（即到最末尾）
        navNext() {
            if (this.move < this.dataNavListRealWidth - this.dataNavListViewWidth - 414) {
                this.move = this.move + 414;
            }
            else {
                this.move = this.dataNavListRealWidth - this.dataNavListViewWidth
            }
        },
    },
    watch: {
        '$route': {
            handler: function (to, from) {
                this.currentTab = "history",
                    this.refreshLotteryCard();
            },
            immediate: true
        }
        
    },
    async mounted() {
        // 如果有帶 queryString,就改變
        if (this.$route.query.tabName) {
            this.currentTab = this.$route.query.tabName;
        }
        this.refreshLotteryCard();
        await this.init();
        // // scrollWidth获取整个菜单实际宽度
        // this.dataNavListRealWidth = this.$refs.dataNavList.scrollWidth;
        // // offsetWidth获取菜单在当前页面可视宽度
        // this.dataNavListViewWidth = this.$refs.dataNavList.offsetWidth;
        // //实际宽度减去可视宽度就是可移动的范围即move的范围
        // // 窗口大小变化时触发。实时更新可视宽度
        
        // window.onresize = () => {
        //     return (() => {
        //         this.dataNavListViewWidth = this.$refs.dataNavList.offsetWidth;
        //         //注1，详见下方
        //         if (this.move > this.dataNavListRealWidth - this.dataNavListViewWidth - 414) {
        //             this.move = this.dataNavListRealWidth - this.dataNavListViewWidth;
        //         }
        //     })()
        // }

    },
    updated(){
        // if(this.$refs.dataNavList != undefined || this.$refs.offsetWidth != undefined){
        //     // scrollWidth获取整个菜单实际宽度
        //     this.dataNavListRealWidth = this.$refs.dataNavList.scrollWidth;
        //     // offsetWidth获取菜单在当前页面可视宽度
        //     this.dataNavListViewWidth = this.$refs.dataNavList.offsetWidth;
        // }
    },
    created: async function(){
        this.init();
    }
}
</script>

<style scoped lang="scss">

.nav-item-active {
    color: red;
    border-color: red;
    border-style: solid;
}

.on-pick-tztk {
    color: #FFFFFF;
    background: #FF6819 0% 0% no-repeat padding-box;
    border-radius: 6px;
}

.wordList {
    width: 1442px;
    display:flex;
    flex-wrap:wrap;
    gap:15px;
    overflow: hidden;
    padding: 20px 15px;
    margin: 10px auto 0;
    /* UI Properties */
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 10px;
    opacity: 1;
}

.wordList>span {
    cursor: pointer;
    font-size: 14px;
    margin: 8px 5px;
    color: #5e5e5e;
  a{padding: 12px 15px;}
}

.wordList>span.active, .wordList>span:hover {
    color: red
}
.tktzContent{
    width: 1442px;
    overflow: hidden;
    padding: 20px 15px;
    border: 1px solid #d7d7d7;
    background-color: #fff;
    margin: 10px auto 58px;
    font-size: 14px;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 10px;
    opacity: 1;

    .topDrawNumber{
        width: 1110px;
        height: 97px;
        margin-left: 183px;
        margin-top: 31px;
        display:flex;
        flex-wrap: wrap;
        flex-direction: column;
        .showPeriodNo{
            width: 335px;
            height: 35px;
            /* UI Properties */
            text-align: left;
            font: normal normal bold 26px/35px Segoe UI;
            letter-spacing: 0px;
            color: #5E5E5E;
            span{
                font: normal normal bold 26px/35px Segoe UI;
                letter-spacing: 0px;
                color: #001C5D;
            }
        }
        .selectPeroid {
            width: 335px; 
            height: 40px;
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            select{
                width: 165px;
                height: 40px;
                /* UI Properties */
                background: #EFEFEF 0% 0% no-repeat padding-box;
                border-radius: 6px;
                opacity: 1;
                text-align: left;
                font: normal normal bold 16px/21px Segoe UI;
                letter-spacing: 0.61px;
                color: #707070;
                border: none;
                    option {
                        text-align: center;
                        font: normal normal bold 16px/21px Segoe UI;
                        letter-spacing: 0.61px;
                        color: #707070;
                        opacity: 1;
                    }
            }
            
            .openTime{
                width: 154px;
                height: 27px;
                margin-left: 15px;
                /* UI Properties */
                text-align: center;
                font-size:20px;
              line-height:27px;
                word-break: keep-all;
                color: #616161;
                opacity: 1;
                &.xglhcLib {
                    width: auto;
                    margin: 8px 0 0 0;
                }
            }
        }
    }
    .topDrawNumber{
        .btmStyle{
            width: 230px;
            height: 90px;
            display: flex;
            .btm{
                width: 95px;
                height: 40px;
                background: #ECECEC 0% 0% no-repeat padding-box;
                border-radius: 6px;
                opacity: 1;
                line-height: 43px;
                margin-left: 20px;
                span{ 
                    text-align: left;
                    font: normal normal bold 16px/21px Segoe UI;
                    letter-spacing: 0px;
                    color: #616161;
                    opacity: 1;
                    a{
                        color: #616161;
                    }
                }
            }
        }
    }
    .showImagesLib{
        margin-left: 131px;
        width: 1174px;
        img{
            background-size: cover;
            width: 100%;
        }
        
    }

    .showBall{
         width: 448px;
         height: 97px;
        .displayType{
            display: flex;
            flex-direction: column;
            .ballWrapper{
                display: flex;
                justify-content: space-around;
                align-items: center;
                .ball{
                    @include ballSize(56px);   
                    margin-right: 2px;
                    font: normal normal bold 33px/44px Segoe UI;
                }
            }
            .textWrapper{
                margin-top: 5px;
                display: flex;
                justify-content: space-around;
                text-align: center;
                font: normal normal bold 26px/35px Segoe UI;
                letter-spacing: 0px;
                color: #616161;
                opacity: 1;
                &.mappingBall_wx{
                    .text{
                        color: #ff0000;
                    }
                
                }
            }
            span{
                width: 335px;
                height: 35px;
                text-align: left;
                font: normal normal 700 26px/35px Segoe UI;
                letter-spacing: 0;
                color: #5e5e5e;
            }
        }
        .add {
            width: 28px;
            height: 53px;
            /* UI Properties */
            text-align: left;
            font: normal normal bold 40px/53px Segoe UI;
            letter-spacing: 0px;
            color: #616161;
            opacity: 1;
        }
    }
    .bottom{
        margin-left: 128px;
    }

    .tzimg{
        width: 100%;
        height: 100%;
        margin-top: 12px;
    }
    .selectImgBtm{
        margin-left: 126px;
        .btmStyle{
            width: 500px;
            height: 47px;
            display: flex;
            text-align: center;
            .btm{
                width: 170px;
                height: 40px;
                background: #ECECEC 0% 0% no-repeat padding-box;
                border-radius: 6px;
                opacity: 1;
                line-height: 40px;
                margin-left: 20px;
                cursor: pointer;
                span{ 
                    text-align: left;
                    font: normal normal bold 16px/21px Segoe UI;
                    letter-spacing: 0px;
                    color: #616161;
                    opacity: 1;
                    margin-left: 8px;
                    a{
                        color: #616161;
                    }
                }
                &.active{
                    background: #FF6819 0% 0% no-repeat padding-box;
                    border-radius: 6px;
                    span{
                        color: #FFFFFF;
                    }
                }
            }
        }
    }

    .dataNav {
         display: flex;
         height: 70px;
         .dataNavList {
             display: flex;
            // 超出部分隐藏
             overflow: hidden;
             .dataNavListItem{
                display: inline-block;
                height: 35px;
                //每个菜单项的宽度要固定，不然move值无法计算，当然也可以通过其他方法来设置以适应自己的表格
                width: 138px; 
                min-width: 138px;
                margin: auto 0;
                border-radius: 5px;
                font: bold 16px Segoe UI;
                text-align: center;
                color: #7E8690;
                line-height: 35px;
                // 过渡效果
                transition:transform .2s;
                &.active{
                color: #FFFFFF;
                cursor: pointer;
                background: #FF6819 0% 0% no-repeat padding-box;
                border-radius: 6px;
             }
             }
             :hover {
                color: #707070;
                cursor: pointer;
                background: #EFEFEF 0% 0% no-repeat padding-box;
                border-radius: 6px;
             }
             
         }
         // 按钮样式
         .dataNavPrev,
         .dataNavNext{
            display: inline-block;
            width: 35px;
            min-width: 35px;
            height: 35px;
            margin: auto 0;
            border: none;
            border-radius: 5px;
            text-align: center;
            line-height:35px;
            background-color: rgba(0,0,0,0);
            &:focus{
                outline: none;
            }
            &:hover{
                //color: #1D4190;
                //background-color: #c7cede;
                cursor: pointer;
            }
         }
         .dataNavNext{
            margin-left: auto;
            background-image: url('~@/img/moveArrow.png');
            background-repeat: no-repeat;
            background-size: cover;
         }
         .dataNavPrev{
            background-image: url('~@/img/moveArrow.png');
            background-repeat: no-repeat;
            background-size: cover;
            transform: rotate(180deg);
         }
 
     }
}

</style>